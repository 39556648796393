'use strict';

import React, {Component} from 'react';
import autoBind from 'react-autobind';

import './Footer.scss';

const email = require('./../assets/img/email-48dpi.png');
const linkedin = require('./../assets/img/linkedin-48px.png');
const github = require('./../assets/img/github-64px.png');

export default class Footer extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
        };
    }


    componentDidMount() { }

    componentDidUpdate() { }

    render() {
        return (
			<div id="Footer">
				<span className='highlight'>Owen Smith |</span> AI, Data and Digital Consultant
				<br />
				<span className='highlight'>ABN</span> 24644301360
				<br />
				<a href="https://github.com/CaffeineFusion"><img src={github} alt='github' className='icon'></img></a>
				<a href="http://www.linkedin.com/in/oclsmith"><img src={linkedin} alt='linkedin' className='icon'></img></a>
				<a href="mailto:hello@owensmith.com.au"><img src={email} alt='email' className='icon email'></img></a>
			</div>
        );
    }

}
