'use strict';

import React, {Component} from 'react';
import autoBind from 'react-autobind';

import './Splash.scss';
//import './../effects/logo.scss';
//import './../effects/typewriter.scss';

import Footer from './../sections/Footer.jsx';
const logo = require('./../assets/img/logo-64px.png');
// TODO: Set up Webpack Dev server to handle non-codifying image assets


export default class Splash extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
        };
    }

    render() {
        return (
			<div id="Splash" className="splash">
				<div id="Content">
					<div id="Title">
						<div className="name "><span className="first">Owen</span> <span className="last">Smith</span></div>
					</div>
					<div id='Sub-Title' className='tag'>
						{/*<div className="logo"><img src={logo} /></div>*/}
						<div >AI<span className='ai'>.</span></div>
						<div>Data<span  className='data'>.</span></div>
						<div>Digital<span className='digital'>.</span></div>
					</div>

				</div>
				<Footer />
			</div>
        );
    }
}
